


.ant_table_mini > div,
.ant_table_mini > div > div,
.ant_table_mini > div > div > div,
.ant_table_mini > div > div > div > div {
  height: 66vh;
}

.ant_table_mini > div > div > div > div {
  min-height: 57vh;
}


.even-row {
  background-color: #ffffff; /* Light background for even rows */
}

.odd-row {
  background-color: #fafafa;/* White background for odd rows */
}



.isDone-row{
  background-color: #f6ffed !important;
}

.isNotDone-row{
  background-color: #ffdedc !important;
}


.header-bg {
  background-color: #1e8ebe; /* Replace with your desired background color */
  color: white; /* Optional: Change text color for better contrast */
  font-size: 15px;
  line-height: 1;
}



/* cart table */


.ant_table_cart > div > div > div,
.ant_table_cart > div > div > div > div {
  height: 60vh;
}


.ant_table_cart .ant-table-body {
  overflow-y: auto; /* Ensure vertical scrolling is enabled */
  overflow-x: hidden; /* Optional: Prevent horizontal scrolling if unnecessary */
}

.ant_table_cart .ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background */
  border-radius: 10px;
}

.ant_table_cart .ant-table-body::-webkit-scrollbar-thumb {
  background: #D3D3D3; /* Gray handle */
  border-radius: 10px;
}

.ant_table_cart .ant-table-body::-webkit-scrollbar {
  width: 8px;
}

.ant_table_cart .ant-table-body::-webkit-scrollbar {
  height: 8px;
}

/* normal  table */


.ant_table > div > div > div,
.ant_table > div > div > div > div {
  height: 74vh;
}

.ant_table > div   {
  min-height: 70vh;
  height: 74vh;
}

.ant_table .ant-table-tbody {
  min-height: 70vh; /* Set a minimum height for the table body */
}

.ant_table .ant-table-body {
  overflow-y: auto; /* Ensure vertical scrolling is enabled */
  overflow-x: hidden; /* Optional: Prevent horizontal scrolling if unnecessary */
}

.ant_table .ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background */
  border-radius: 10px;
}

.ant_table .ant-table-body::-webkit-scrollbar-thumb {
  background: #D3D3D3; /* Gray handle */
  border-radius: 10px;
}

.ant_table .ant-table-body::-webkit-scrollbar {
  width: 8px;
}

.ant_table .ant-table-body::-webkit-scrollbar {
  height: 8px;
}